// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as Tooltip from "../../../shared/components/Tooltip.bs.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesStudents__DistributionInLevel from "../types/CoursesStudents__DistributionInLevel.bs.js";

import "./CoursesStudents__StudentDistribution.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__StudentDistribution";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function stylingForLevelPills(percentageStudents) {
  var emptyStyle = {};
  var styleWithWidth = {
    width: percentageStudents.toString() + "%"
  };
  if (0.0 === percentageStudents) {
    return [
            "w-8 flex-grow",
            emptyStyle,
            "bg-green-200 text-green-800"
          ];
  } else if (0.0 <= percentageStudents && percentageStudents < 5.0) {
    return [
            "w-8 flex-shrink-0",
            emptyStyle,
            "bg-green-200 text-green-800"
          ];
  } else if (5.0 <= percentageStudents && percentageStudents < 20.0) {
    return [
            "",
            styleWithWidth,
            "bg-green-300 text-green-800"
          ];
  } else if (20.0 <= percentageStudents && percentageStudents < 40.0) {
    return [
            "",
            styleWithWidth,
            "bg-green-400 text-green-900"
          ];
  } else if (40.0 <= percentageStudents && percentageStudents < 60.0) {
    return [
            "",
            styleWithWidth,
            "bg-green-500 text-white"
          ];
  } else if (60.0 <= percentageStudents && percentageStudents < 80.0) {
    return [
            "",
            styleWithWidth,
            "bg-green-600 text-white"
          ];
  } else {
    return [
            "",
            styleWithWidth,
            "bg-green-700 text-white"
          ];
  }
}

function onLevelSelect(value, params, href) {
  if (params !== undefined) {
    var p = Caml_option.valFromOption(params);
    p.set("level", value);
    return RescriptReactRouter.push("?" + p.toString());
  }
  var search = window.location.search;
  var params$1 = new URLSearchParams(search);
  params$1.set("level", value);
  var currentPath = window.location.pathname;
  var searchString = params$1.toString();
  var path = Belt_Option.getWithDefault(href, currentPath);
  window.location = path + "?" + searchString;
  
}

var studentDistributionSkeleton = React.createElement("div", {
      className: "skeleton-body-container w-full mx-auto"
    }, React.createElement("div", {
          className: "skeleton-body-wrapper px-3 lg:px-0"
        }, React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "w-1/6"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate w-6 mx-auto"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-md skeleton-animate mt-2"
                    })), React.createElement("div", {
                  className: "w-5/12"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate w-6 mx-auto"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-md skeleton-animate mt-2"
                    })), React.createElement("div", {
                  className: "w-1/4"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate w-6 mx-auto"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-md skeleton-animate mt-2"
                    })), React.createElement("div", {
                  className: "w-1/12"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate w-6 mx-auto"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-md skeleton-animate mt-2"
                    })), React.createElement("div", {
                  className: "w-1/12"
                }, React.createElement("div", {
                      className: "skeleton-placeholder__line-sm skeleton-animate w-6 mx-auto"
                    }), React.createElement("div", {
                      className: "skeleton-placeholder__line-md skeleton-animate mt-2"
                    })))));

function CoursesStudents__StudentDistribution(Props) {
  var studentDistribution = Props.studentDistribution;
  var params = Props.params;
  var href = Props.href;
  var totalStudentsInCourse = studentDistribution.reduce((function (x, y) {
          return x + CoursesStudents__DistributionInLevel.studentsInLevel(y) | 0;
        }), 0);
  var completedLevels = CoursesStudents__DistributionInLevel.levelsCompletedByAllStudents(studentDistribution);
  return React.createElement("div", {
              "aria-label": "Students level-wise distribution",
              className: "w-full py-4"
            }, React.createElement("div", {
                  className: "flex w-full border bg-gray-50 rounded font-semibold "
                }, CoursesStudents__DistributionInLevel.sort(studentDistribution.filter(function (level) {
                            return CoursesStudents__DistributionInLevel.number(level) !== 0;
                          })).map(function (level) {
                      var percentageStudents = CoursesStudents__DistributionInLevel.percentageStudents(level, totalStudentsInCourse);
                      var match = stylingForLevelPills(percentageStudents);
                      var tip = React.createElement("div", {
                            className: "text-left"
                          }, React.createElement("p", undefined, LevelLabel.format(undefined, undefined, String(CoursesStudents__DistributionInLevel.number(level)))), React.createElement("p", undefined, ts(undefined, undefined, "students") + ": " + String(CoursesStudents__DistributionInLevel.studentsInLevel(level))), React.createElement("p", undefined, ts(undefined, undefined, "percentage") + ": " + percentageStudents.toFixed(1)));
                      return React.createElement("div", {
                                  key: CoursesStudents__DistributionInLevel.id(level),
                                  "aria-label": "Students in level " + String(CoursesStudents__DistributionInLevel.number(level)),
                                  className: "student-distribution__container text-center relative focus-within:outline-none focus-within:opacity-75 " + match[0],
                                  style: match[1]
                                }, React.createElement("label", {
                                      className: "absolute -mt-5 left-0 right-0 inline-block text-xs text-primary-400 text-center",
                                      htmlFor: tr(undefined, undefined, "students_level") + String(CoursesStudents__DistributionInLevel.number(level))
                                    }, CoursesStudents__DistributionInLevel.shortName(level)), React.createElement(Tooltip.make, {
                                      tip: tip,
                                      className: "w-full",
                                      position: "Bottom",
                                      children: React.createElement("button", {
                                            className: "student-distribution__pill w-full hover:shadow-inner focus:shadow-inner relative cursor-pointer border-white text-xs leading-none text-center " + (
                                              completedLevels.includes(level) ? "bg-yellow-300 text-yellow-900" : (
                                                  CoursesStudents__DistributionInLevel.unlocked(level) ? match[2] : "student-distribution__pill--locked cursor-default bg-gray-300 text-gray-800"
                                                )
                                            ),
                                            id: tr(undefined, undefined, "students_level") + String(CoursesStudents__DistributionInLevel.number(level)),
                                            onClick: (function (param) {
                                                return onLevelSelect(CoursesStudents__DistributionInLevel.filterName(level), params, href);
                                              })
                                          }, completedLevels.includes(level) ? React.createElement(PfIcon.make, {
                                                  className: "if i-check-solid text-tiny"
                                                }) : React.createElement("div", undefined, React.createElement("div", {
                                                      className: CoursesStudents__DistributionInLevel.unlocked(level) ? "" : "student-distribution__team-count-value"
                                                    }, String(CoursesStudents__DistributionInLevel.studentsInLevel(level))), CoursesStudents__DistributionInLevel.unlocked(level) ? null : React.createElement("div", {
                                                        className: "student-distribution__locked-icon"
                                                      }, React.createElement("i", {
                                                            className: "fas fa-lock text-tiny"
                                                          }))))
                                    }));
                    })));
}

function makeFromJson(props) {
  var studentDistribution = Json_decode.field("studentDistribution", (function (param) {
          return Json_decode.array(CoursesStudents__DistributionInLevel.decode, param);
        }), props);
  var href = Json_decode.optional((function (param) {
          return Json_decode.field("href", Json_decode.string, param);
        }), props);
  return CoursesStudents__StudentDistribution({
              studentDistribution: studentDistribution,
              params: undefined,
              href: href
            });
}

var make = CoursesStudents__StudentDistribution;

export {
  str ,
  tr ,
  ts ,
  stylingForLevelPills ,
  onLevelSelect ,
  studentDistributionSkeleton ,
  make ,
  makeFromJson ,
  
}
/*  Not a pure module */
