// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as MarkdownEditor from "./MarkdownEditor.bs.js";

function SimpleMarkdownEditor(Props) {
  var value = Props.value;
  var profile = Props.profile;
  var textareaId = Props.textareaId;
  var maxLengthOpt = Props.maxLength;
  var defaultModeOpt = Props.defaultMode;
  var placeholder = Props.placeholder;
  var tabIndex = Props.tabIndex;
  var textAreaName = Props.textAreaName;
  var fileUploadOpt = Props.fileUpload;
  var disabledOpt = Props.disabled;
  var dynamicHeightOpt = Props.dynamicHeight;
  var maxLength = maxLengthOpt !== undefined ? maxLengthOpt : 1000;
  var defaultMode = defaultModeOpt !== undefined ? defaultModeOpt : ({
        TAG: /* Windowed */1,
        _0: "Editor"
      });
  var fileUpload = fileUploadOpt !== undefined ? fileUploadOpt : true;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var dynamicHeight = dynamicHeightOpt !== undefined ? dynamicHeightOpt : false;
  var match = React.useState(function () {
        return value;
      });
  var setState = match[1];
  var tmp = {
    value: match[0],
    onChange: (function (value) {
        return Curry._1(setState, (function (param) {
                      return value;
                    }));
      }),
    profile: profile,
    maxLength: maxLength,
    defaultMode: defaultMode,
    fileUpload: fileUpload,
    disabled: disabled,
    dynamicHeight: dynamicHeight
  };
  if (textareaId !== undefined) {
    tmp.textareaId = Caml_option.valFromOption(textareaId);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (tabIndex !== undefined) {
    tmp.tabIndex = Caml_option.valFromOption(tabIndex);
  }
  if (textAreaName !== undefined) {
    tmp.textAreaName = Caml_option.valFromOption(textAreaName);
  }
  return React.createElement(MarkdownEditor.make, tmp);
}

function makeFromJson(props) {
  var profileAsString = Json_decode.field("profile", Json_decode.string, props);
  var profile;
  switch (profileAsString) {
    case "AreaOfText" :
        profile = /* AreaOfText */1;
        break;
    case "Permissive" :
        profile = /* Permissive */0;
        break;
    default:
      profile = /* AreaOfText */1;
  }
  return SimpleMarkdownEditor({
              profile: profile,
              value: Json_decode.field("value", Json_decode.string, props),
              textareaId: Json_decode.optional((function (param) {
                      return Json_decode.field("textareaId", Json_decode.string, param);
                    }), props),
              maxLength: Json_decode.optional((function (param) {
                      return Json_decode.field("maxLength", Json_decode.$$int, param);
                    }), props),
              placeholder: Json_decode.optional((function (param) {
                      return Json_decode.field("placeholder", Json_decode.string, param);
                    }), props),
              tabIndex: Json_decode.optional((function (param) {
                      return Json_decode.field("tabIndex", Json_decode.$$int, param);
                    }), props),
              textAreaName: Json_decode.optional((function (param) {
                      return Json_decode.field("textAreaName", Json_decode.string, param);
                    }), props),
              fileUpload: Json_decode.optional((function (param) {
                      return Json_decode.field("fileUpload", Json_decode.bool, param);
                    }), props),
              disabled: Json_decode.optional((function (param) {
                      return Json_decode.field("disabled", Json_decode.bool, param);
                    }), props),
              dynamicHeight: Json_decode.optional((function (param) {
                      return Json_decode.field("dynamicHeight", Json_decode.bool, param);
                    }), props),
              defaultMode: {
                TAG: /* Windowed */1,
                _0: "Editor"
              }
            });
}

var make = SimpleMarkdownEditor;

export {
  make ,
  makeFromJson ,
  
}
/* react Not a pure module */
