// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../Icon.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Dropdown2 from "./Dropdown2.bs.js";
import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as Pervasives from "../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as MultiselectDropdown from "../../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js";

function str(prim) {
  return prim;
}

function makeFilter(key, label, filterType, color) {
  return {
          key: key,
          label: label,
          filterType: filterType,
          color: color
        };
}

function makeSorter(key, options, $$default) {
  return {
          key: key,
          options: options,
          default: $$default
        };
}

function reducer(_state, action) {
  if (action) {
    return {
            filterInput: action._0
          };
  } else {
    return {
            filterInput: ""
          };
  }
}

function removeIdFromString(string) {
  return string.replace(/^\d+;/, "");
}

function value(t) {
  return t.displayValue;
}

function label(t) {
  return t.label;
}

function key(t) {
  return t.key;
}

function color(t) {
  return t.color;
}

function orginalValue(t) {
  return t.orginalValue;
}

function searchString(t) {
  return Belt_Option.getWithDefault(t.label, t.key) + " " + t.displayValue;
}

function make(key, value, label, color) {
  return {
          key: key,
          orginalValue: value,
          displayValue: removeIdFromString(value),
          label: label,
          color: color
        };
}

var Selectable = {
  value: value,
  label: label,
  key: key,
  color: color,
  orginalValue: orginalValue,
  searchString: searchString,
  make: make
};

var Multiselect = MultiselectDropdown.Make({
      label: label,
      value: value,
      searchString: searchString,
      color: color
    });

function unselected(state, filters) {
  return ArrayUtils.flattenV2(filters.map(function (filter) {
                  var value = filter.filterType;
                  if (typeof value === "number") {
                    if (state.filterInput === "") {
                      return [];
                    } else {
                      return [make(filter.key, state.filterInput, filter.label, filter.color)];
                    }
                  } else if (value.TAG === /* SingleSelect */0) {
                    return [make(filter.key, value._0, filter.label, filter.color)];
                  } else {
                    return value._0.map(function (value) {
                                return make(filter.key, value, filter.label, filter.color);
                              });
                  }
                }));
}

function computeInitialState(param) {
  return {
          filterInput: ""
        };
}

function selectedFromQueryParams(params, filters) {
  return ArrayUtils.flattenV2(filters.map(function (filter) {
                  var value = params.get(filter.key);
                  if (value == null) {
                    return [];
                  } else {
                    return [make(filter.key, value, filter.label, filter.color)];
                  }
                }));
}

function setParams(key, value, params) {
  params.set(key, value);
  
}

function navigateTo(params) {
  var currentPath = window.location.pathname;
  var searchString = params.toString();
  window.location = currentPath + "?" + searchString;
  
}

function onSelect(params, send, selectable) {
  setParams(selectable.key, selectable.orginalValue, params);
  Curry._1(send, /* ClearFilterString */0);
  return navigateTo(params);
}

function onDeselect(params, selectable) {
  params.delete(selectable.key);
  return navigateTo(params);
}

function selectedSorter(sorter, params) {
  var userSuppliedValue = params.get(sorter.key);
  var value = !(userSuppliedValue == null) && sorter.options.includes(userSuppliedValue) ? userSuppliedValue : sorter.default;
  return React.createElement("button", {
              className: "p-3 w-36 text-sm font-medium space-x-2 text-left truncate cursor-pointer bg-white border border-gray-300 text-gray-900 rounded-md hover:bg-primary-100 hover:text-primary-400 hover:border-primary-400 focus:outline-none focus:bg-primary-100 focus:text-primary-400 focus:border-primary-400",
              title: "Order by " + value
            }, React.createElement(Icon.make, {
                  className: "if i-sort-alpha-ascending-regular"
                }), React.createElement("span", undefined, value));
}

function sortOptions(sorter, params) {
  return sorter.options.map(function (sort) {
              return React.createElement("button", {
                          key: sort,
                          className: "w-full cursor-pointer text-left block p-3 text-xs font-semibold text-gray-900 border-b border-gray-50 bg-white hover:text-primary-400 hover:bg-gray-50 focus:outline-none focus:text-primary-400 focus:bg-gray-50",
                          title: "Order by " + sort,
                          onClick: (function (_e) {
                              setParams(sorter.key, sort, params);
                              return navigateTo(params);
                            })
                        }, sort);
            });
}

function SimpleDropdownFilter(Props) {
  var id = Props.id;
  var filters = Props.filters;
  var placeholderOpt = Props.placeholder;
  var sorter = Props.sorter;
  var hintOpt = Props.hint;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Search or filter...";
  var hint = hintOpt !== undefined ? hintOpt : "...or start typing to search.";
  var search = window.location.search;
  var match = React.useReducer(reducer, {
        filterInput: ""
      });
  var send = match[1];
  var state = match[0];
  var params = new URLSearchParams(search);
  return React.createElement("div", {
              className: "bg-black-50 p-4 w-full flex flex-wrap gap-3 rounded-lg"
            }, React.createElement("div", {
                  className: "flex-1"
                }, React.createElement("label", {
                      className: "text-xs uppercase font-medium pb-2",
                      htmlFor: id
                    }, "Filter"), React.createElement(Multiselect.make, {
                      id: id,
                      placeholder: placeholder,
                      onChange: (function (filterInput) {
                          return Curry._1(send, /* UpdateFilterInput */{
                                      _0: filterInput
                                    });
                        }),
                      value: state.filterInput,
                      unselected: unselected(state, filters),
                      selected: selectedFromQueryParams(params, filters),
                      onSelect: (function (param) {
                          return onSelect(params, send, param);
                        }),
                      onDeselect: (function (param) {
                          return onDeselect(params, param);
                        }),
                      hint: hint,
                      defaultOptions: unselected(state, filters)
                    })), sorter !== undefined ? React.createElement("div", undefined, React.createElement("p", {
                        className: "text-xs uppercase font-medium pb-2"
                      }, "Sort by"), React.createElement(Dropdown2.make, {
                        selected: selectedSorter(sorter, params),
                        contents: sortOptions(sorter, params),
                        right: true
                      })) : null);
}

function decodeFilter(json) {
  var unknownFilterType = Json_decode.field("filterType", Json_decode.string, json);
  var tmp;
  switch (unknownFilterType) {
    case "MultiSelect" :
        tmp = {
          TAG: /* MultiSelect */1,
          _0: Json_decode.field("values", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
        break;
    case "Search" :
        tmp = /* Search */0;
        break;
    case "SingleSelect" :
        tmp = {
          TAG: /* SingleSelect */0,
          _0: Json_decode.field("value", Json_decode.string, json)
        };
        break;
    default:
      tmp = Pervasives.failwith("Unknown filter type: " + unknownFilterType);
  }
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          label: Json_decode.field("label", Json_decode.string, json),
          filterType: tmp,
          color: Json_decode.field("color", Json_decode.string, json)
        };
}

function decodeSorter(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          options: Json_decode.field("options", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          default: Json_decode.field("default", Json_decode.string, json)
        };
}

function makeFromJson(json) {
  return SimpleDropdownFilter({
              id: Json_decode.field("id", Json_decode.string, json),
              filters: Json_decode.field("filters", (function (param) {
                      return Json_decode.array(decodeFilter, param);
                    }), json),
              placeholder: Json_decode.optional((function (param) {
                      return Json_decode.field("placeholder", Json_decode.string, param);
                    }), json),
              sorter: Json_decode.optional((function (param) {
                      return Json_decode.field("sorter", decodeSorter, param);
                    }), json),
              hint: Json_decode.optional((function (param) {
                      return Json_decode.field("hint", Json_decode.string, param);
                    }), json)
            });
}

var make$1 = SimpleDropdownFilter;

export {
  str ,
  makeFilter ,
  makeSorter ,
  reducer ,
  removeIdFromString ,
  Selectable ,
  Multiselect ,
  unselected ,
  computeInitialState ,
  selectedFromQueryParams ,
  setParams ,
  navigateTo ,
  onSelect ,
  onDeselect ,
  selectedSorter ,
  sortOptions ,
  make$1 as make,
  decodeFilter ,
  decodeSorter ,
  makeFromJson ,
  
}
/* Multiselect Not a pure module */
