// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../shared/Api.bs.js";
import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../../node_modules/rescript/lib/es6/js_json.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";

import "./CalendarsIndex__DatePicker.css"
;

var InvalidSource = /* @__PURE__ */Caml_exceptions.create("CalendarsIndex__DatePicker.InvalidSource");

var WeekDayInvalid = /* @__PURE__ */Caml_exceptions.create("CalendarsIndex__DatePicker.WeekDayInvalid");

function str(prim) {
  return prim;
}

var computeStatus = $$Array.mem;

var partial_arg = "shared.weekdays";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function startOnDayClass(currentMonth) {
  var day = currentMonth.getDay() | 0;
  switch (day) {
    case 0 :
        return "sun";
    case 1 :
        return "mon";
    case 2 :
        return "tue";
    case 3 :
        return "wed";
    case 4 :
        return "thu";
    case 5 :
        return "fri";
    case 6 :
        return "sat";
    default:
      throw {
            RE_EXN_ID: WeekDayInvalid,
            _1: t(undefined, undefined, "error"),
            Error: new Error()
          };
  }
}

function computeSelectedMonth(state) {
  var currentDate = new Date();
  var month = currentDate.getMonth() + state.selectedMonthDeviation;
  var year = currentDate.getFullYear();
  return new Date(year, month);
}

function reducer(state, action) {
  if (typeof action !== "number") {
    if (action.TAG === /* ChangeDate */0) {
      return {
              selectedDate: action._0,
              selectedMonthDeviation: state.selectedMonthDeviation,
              dayEventsLoadStatus: state.dayEventsLoadStatus
            };
    } else {
      return {
              selectedDate: state.selectedDate,
              selectedMonthDeviation: state.selectedMonthDeviation,
              dayEventsLoadStatus: /* Loaded */{
                _0: action._0
              }
            };
    }
  }
  switch (action) {
    case /* ChangeToNextMonth */0 :
        return {
                selectedDate: state.selectedDate,
                selectedMonthDeviation: state.selectedMonthDeviation + 1 | 0,
                dayEventsLoadStatus: /* Loading */0
              };
    case /* ChangeToPreviousMonth */1 :
        return {
                selectedDate: state.selectedDate,
                selectedMonthDeviation: state.selectedMonthDeviation - 1 | 0,
                dayEventsLoadStatus: /* Loading */0
              };
    case /* ChangeDateToToday */2 :
        return {
                selectedDate: new Date(),
                selectedMonthDeviation: 0,
                dayEventsLoadStatus: state.dayEventsLoadStatus
              };
    case /* StartLoadingStatus */3 :
        return {
                selectedDate: state.selectedDate,
                selectedMonthDeviation: state.selectedMonthDeviation,
                dayEventsLoadStatus: /* Loading */0
              };
    
  }
}

function decodeJsonAsStringArray(x) {
  return Json_decode.array(Json_decode.string, x);
}

function reloadPage(selectedDate) {
  var search = window.location.search;
  var params = new URLSearchParams(search);
  params.set("date", selectedDate);
  var currentPath = window.location.pathname;
  var searchString = params.toString();
  window.location = currentPath + "?" + searchString;
  
}

function daysOfMonth(selectedMonth, selectedDate, dayStatuses) {
  var daysInMonth = DateFns.getDaysInMonth(selectedMonth) | 0;
  var daysAsArray = Caml_array.make(daysInMonth, 1).map(function (day, index) {
        return day + index | 0;
      });
  var currentMonthAsString = DateFns.format(selectedMonth, "yyyy-MM-");
  var selectedDateAsString = DateFns.format(selectedDate, "yyyy-MM-dd");
  var parsedStatuses = Js_dict.map(decodeJsonAsStringArray, Belt_Option.getWithDefault(Js_json.decodeObject(dayStatuses), {}));
  return daysAsArray.map(function (day) {
              var dayAsString = currentMonthAsString + (
                day < 10 ? "0" + String(day) : String(day)
              );
              var dayStatus = Belt_Option.getWithDefault(Js_dict.get(parsedStatuses, dayAsString), []);
              return React.createElement("button", {
                          key: dayAsString,
                          className: "courses-calendar__date-grid-button " + (
                            selectedDateAsString === dayAsString ? "courses-calendar__date-grid-button--is-selected" : "hover:text-primary-400 hover:bg-primary-100 focus:bg-primary-100 focus:ring-2 focus:ring-focusColor-500 transition"
                          ),
                          onClick: (function (param) {
                              return reloadPage(dayAsString);
                            })
                        }, React.createElement("time", {
                              dateTime: dayAsString
                            }, String(day)), selectedDateAsString === dayAsString || ArrayUtils.isEmpty(dayStatus) ? null : React.createElement("div", {
                                className: "flex justify-center mt-1 space-x-1"
                              }, dayStatus.map(function (color) {
                                    return React.createElement("span", {
                                                className: "h-1.5 w-1.5 bg-" + color + "-500 rounded-full"
                                              });
                                  })));
            });
}

function getMonthEventStatus(selectedMonth, source, selectedCalendarId, courseId, send) {
  Curry._1(send, /* StartLoadingStatus */3);
  var monthStartAsString = DateFns.format(selectedMonth, "yyyy-MM") + "-01";
  var path;
  switch (source) {
    case "admin" :
        path = "/school/courses/";
        break;
    case "student" :
        path = "/courses/";
        break;
    default:
      throw {
            RE_EXN_ID: InvalidSource,
            _1: "Invalid source",
            Error: new Error()
          };
  }
  var url = path + courseId + "/calendar_month_data?date=" + monthStartAsString + (
    selectedCalendarId !== undefined ? "&calendar_id=" + selectedCalendarId : ""
  );
  return Api.get(url, (function (res) {
                return Curry._1(send, {
                            TAG: /* FinishLoadingStatus */1,
                            _0: res
                          });
              }), (function (param) {
                return Curry._1(send, {
                            TAG: /* FinishLoadingStatus */1,
                            _0: null
                          });
              }), false);
}

function CalendarsIndex__DatePicker(Props) {
  var selectedDate = Props.selectedDate;
  var source = Props.source;
  var selectedCalendarId = Props.selectedCalendarId;
  var courseId = Props.courseId;
  var match = React.useReducer(reducer, {
        selectedDate: DateFns.parseISO(selectedDate),
        selectedMonthDeviation: DateFns.differenceInCalendarMonths(DateFns.parseISO(selectedDate), new Date()),
        dayEventsLoadStatus: /* Loading */0
      });
  var send = match[1];
  var state = match[0];
  var selectedMonth = computeSelectedMonth(state);
  React.useEffect((function () {
          getMonthEventStatus(selectedMonth, source, selectedCalendarId, courseId, send);
          
        }), [state.selectedMonthDeviation]);
  var statuses = state.dayEventsLoadStatus;
  return React.createElement("div", {
              className: "sticky top-0 z-50"
            }, React.createElement("section", undefined, React.createElement("div", {
                      className: "courses-calendar__container 2xl:px-3"
                    }, React.createElement("div", {
                          className: "flex justify-between"
                        }, React.createElement("div", {
                              className: "courses-calendar__month-indicator flex items-center"
                            }, React.createElement("button", {
                                  className: "flex justify-center items-center cursor-pointer h-7 w-7 p-1 text-sm border border-gray-200 rounded-md hover:text-primary-400 hover:bg-primary-100 focus:ring-2 focus:ring-focusColor-500 transition",
                                  onClick: (function (param) {
                                      return Curry._1(send, /* ChangeToPreviousMonth */1);
                                    })
                                }, React.createElement("i", {
                                      className: "fas fa-chevron-left mr-px"
                                    })), React.createElement("time", {
                                  className: "px-2 md:px-4 text-sm xl:text-base",
                                  dateTime: "2020-06"
                                }, DateFns.format(selectedMonth, "MMM yyyy")), React.createElement("button", {
                                  className: "flex justify-center items-center cursor-pointer h-7 w-7 p-1 text-sm border border-gray-200 rounded-md hover:text-primary-400 hover:bg-primary-100 focus:ring-2 focus:ring-focusColor-500 transition",
                                  onClick: (function (param) {
                                      return Curry._1(send, /* ChangeToNextMonth */0);
                                    })
                                }, React.createElement("i", {
                                      className: "fas fa-chevron-right ml-px"
                                    }))), React.createElement("button", {
                              className: "px-2 py-1 text-sm font-medium border rounded-md hover:text-primary-400 hover:bg-primary-100 focus:ring-2 focus:ring-focusColor-500 transition",
                              onClick: (function (param) {
                                  return reloadPage(DateFns.format(new Date(), "yyyy-MM-dd"));
                                })
                            }, React.createElement("span", undefined, t(undefined, undefined, "today")))), React.createElement("div", {
                          className: "courses-calendar__day-of-week"
                        }, React.createElement("div", undefined, t(undefined, undefined, "sun")), React.createElement("div", undefined, t(undefined, undefined, "mon")), React.createElement("div", undefined, t(undefined, undefined, "tue")), React.createElement("div", undefined, t(undefined, undefined, "wed")), React.createElement("div", undefined, t(undefined, undefined, "thu")), React.createElement("div", undefined, t(undefined, undefined, "fri")), React.createElement("div", undefined, t(undefined, undefined, "sat"))), React.createElement("div", undefined, statuses ? React.createElement("div", {
                                className: "courses-calendar__date-grid courses-calendar__date-grid--start-on-" + startOnDayClass(selectedMonth)
                              }, daysOfMonth(selectedMonth, state.selectedDate, statuses._0)) : SkeletonLoading.calendar(undefined)))));
}

function makeFromJson(json) {
  return CalendarsIndex__DatePicker({
              selectedDate: Json_decode.field("selectedDate", Json_decode.string, json),
              selectedCalendarId: Json_decode.optional((function (param) {
                      return Json_decode.field("selectedCalendarId", Json_decode.string, param);
                    }), json),
              courseId: Json_decode.field("courseId", Json_decode.string, json),
              source: Json_decode.field("source", Json_decode.string, json)
            });
}

var make = CalendarsIndex__DatePicker;

export {
  InvalidSource ,
  WeekDayInvalid ,
  str ,
  computeStatus ,
  t ,
  startOnDayClass ,
  computeSelectedMonth ,
  reducer ,
  decodeJsonAsStringArray ,
  reloadPage ,
  daysOfMonth ,
  getMonthEventStatus ,
  make ,
  makeFromJson ,
  
}
/*  Not a pure module */
