// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as React from "react";

import "./DisablingCover.css"
;

var partial_arg = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function DisablingCover(Props) {
  var disabled = Props.disabled;
  var messageOpt = Props.message;
  var containerClassesOpt = Props.containerClasses;
  var children = Props.children;
  var message = messageOpt !== undefined ? messageOpt : ts(undefined, undefined, "loading");
  var containerClasses = containerClassesOpt !== undefined ? containerClassesOpt : "";
  return React.createElement("div", {
              className: "relative " + containerClasses
            }, disabled ? [
                React.createElement("div", {
                      key: "school-admin-disabling-cover__blanket",
                      className: "absolute w-full h-full bg-white opacity-75 z-20 flex items-center justify-center"
                    }),
                React.createElement("div", {
                      key: "school-admin-disabling-cover__body",
                      className: "absolute w-full h-full z-20 flex items-center justify-center"
                    }, React.createElement("div", {
                          className: "disabling-cover__loading-container bg-white rounded-lg shadow-xl p-4"
                        }, React.createElement("div", {
                              className: "disabling-cover__loading-animation-box mx-auto"
                            }, React.createElement("div", {
                                  className: "disabling-cover__loading-box-1"
                                }), React.createElement("div", {
                                  className: "disabling-cover__loading-box-2"
                                }), React.createElement("div", {
                                  className: "disabling-cover__loading-box-3"
                                })), React.createElement("span", {
                              className: "block pt-2 font-semibold max-w-sm text-center text-sm"
                            }, message)))
              ] : null, children);
}

var make = DisablingCover;

export {
  ts ,
  make ,
  
}
/*  Not a pure module */
