// Generated by ReScript, PLEASE EDIT WITH CARE


function randomId(param) {
  var number = Math.random().toString();
  var time = Date.now().toString();
  return ("I" + (time + number)).replace(".", "-");
}

export {
  randomId ,
  
}
/* No side effect */
