// Generated by ReScript, PLEASE EDIT WITH CARE

import PrismjsWrapper from "./prismjsWrapper";

function highlightAllUnderJs(prim) {
  PrismjsWrapper(prim);
  
}

function highlightAllUnder(elementId) {
  var wrapperElement = document.getElementById(elementId);
  if (!(wrapperElement == null)) {
    PrismjsWrapper(wrapperElement);
    return ;
  }
  
}

export {
  highlightAllUnderJs ,
  highlightAllUnder ,
  
}
/* ./prismjsWrapper Not a pure module */
