// Generated by ReScript, PLEASE EDIT WITH CARE

import Dompurify from "dompurify";
import * as ArrayUtils from "./ArrayUtils.bs.js";

function sanitize(s) {
  return Dompurify.sanitize(s);
}

function makeOptions(addTagsOpt, allowedTagsOpt, param) {
  var addTags = addTagsOpt !== undefined ? addTagsOpt : [];
  var allowedTags = allowedTagsOpt !== undefined ? allowedTagsOpt : [];
  var opt = {};
  if (ArrayUtils.isNotEmpty(addTags)) {
    opt["ADD_TAGS"] = addTags;
    opt["ADD_ATTR"] = [
      "allowfullscreen",
      "webkitallowfullscreen",
      "mozallowfullscreen"
    ];
  }
  if (ArrayUtils.isNotEmpty(allowedTags)) {
    opt["ALLOWED_TAGS"] = allowedTags;
  }
  return opt;
}

function sanitizeOpt(s, opt) {
  return Dompurify.sanitize(s, opt);
}

function sanitizedHTML(html) {
  return {
          __html: Dompurify.sanitize(html)
        };
}

function sanitizedHTMLOpt(html, options) {
  return {
          __html: Dompurify.sanitize(html, options)
        };
}

function sanitizedHTMLHook(entryPoint, hookFunction) {
  return Dompurify.addHook(entryPoint, hookFunction);
}

document.addEventListener(
    "DOMContentLoaded", () => {

      sanitizedHTMLHook('uponSanitizeElement', (node, data) => {
        if (data.tagName === 'iframe') {
          const src = node.getAttribute('src') || ''
          if (!(src.startsWith('https://www.youtube.com/embed/') ||
            src.startsWith('https://player.vimeo.com/video/'))
          ) {
            return node.parentNode?.removeChild(node)
          }
        }
      })

      sanitizedHTMLHook('afterSanitizeAttributes', function(node) {
          // set all elements owning target to target=_blank
          if ('target' in node) {
              node.setAttribute('target','_blank');
              // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
              node.setAttribute('rel', 'noopener noreferrer');
          }
          // set non-HTML/MathML links to xlink:show=new
          if (!node.hasAttribute('target')
              && (node.hasAttribute('xlink:href')
                  || node.hasAttribute('href'))) {
              node.setAttribute('xlink:show', 'new');
          }
      });
    },
    false
  );
;

export {
  sanitize ,
  makeOptions ,
  sanitizeOpt ,
  sanitizedHTML ,
  sanitizedHTMLOpt ,
  sanitizedHTMLHook ,
  
}
/*  Not a pure module */
