// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";

var transformIcons = PfIcon.transformIcons;

var make = PfIcon.make;

export {
  transformIcons ,
  make ,
  
}
/* PfIcon Not a pure module */
