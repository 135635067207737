// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

import "./LevelProgressBar.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.LevelProgressBar";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function levelClasses(levelNumber, levelCompleted, currentLevelNumber) {
  var reached = Caml_obj.caml_lessequal(levelNumber, currentLevelNumber) ? "level-progress-bar__student-level--reached" : "";
  var current = Caml_obj.caml_equal(levelNumber, currentLevelNumber) ? " level-progress-bar__student-level--current" : "";
  var completed = levelCompleted ? " level-progress-bar__student-level--completed" : "";
  return reached + (current + completed);
}

function LevelProgressBar(Props) {
  var levels = Props.levels;
  var currentLevelNumber = Props.currentLevelNumber;
  var courseCompleted = Props.courseCompleted;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: "flex justify-between items-end"
                }, React.createElement("h6", {
                      className: "text-sm font-semibold"
                    }, t(undefined, undefined, "heading"))), React.createElement("div", {
                  className: "h-12 flex items-center"
                }, React.createElement("ul", {
                      className: "level-progress-bar__student-progress flex w-full " + (
                        courseCompleted ? "level-progress-bar__student-progress--completed" : ""
                      )
                    }, levels.map(function (levelCompleted, index) {
                          var levelNumber = index + 1 | 0;
                          return React.createElement("li", {
                                      key: String(levelNumber),
                                      className: "flex-1 level-progress-bar__student-level " + levelClasses(levelNumber, levelCompleted, currentLevelNumber)
                                    }, React.createElement("span", {
                                          className: "level-progress-bar__student-level-count"
                                        }, String(levelNumber)));
                        }))));
}

function makeFromJson(json) {
  return LevelProgressBar({
              levels: Json_decode.field("levels", (function (param) {
                      return Json_decode.array(Json_decode.bool, param);
                    }), json),
              currentLevelNumber: Json_decode.field("currentLevelNumber", Json_decode.$$int, json),
              courseCompleted: Json_decode.field("courseCompleted", Json_decode.bool, json),
              className: Json_decode.optional((function (param) {
                      return Json_decode.field("className", Json_decode.string, param);
                    }), json)
            });
}

var make = LevelProgressBar;

export {
  str ,
  t ,
  levelClasses ,
  make ,
  makeFromJson ,
  
}
/*  Not a pure module */
