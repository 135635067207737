// Generated by ReScript, PLEASE EDIT WITH CARE

import Notifier from "./notifier";

function jsNotify(prim0, prim1, prim2) {
  Notifier(prim0, prim1, prim2);
  
}

function success(title, text) {
  return jsNotify(title, text, "success");
}

function error(title, text) {
  return jsNotify(title, text, "error");
}

function notice(title, text) {
  return jsNotify(title, text, "notice");
}

var warn = notice;

export {
  jsNotify ,
  success ,
  error ,
  notice ,
  warn ,
  
}
/* ./notifier Not a pure module */
