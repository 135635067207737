// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function number(t) {
  return t.number;
}

function studentsInLevel(t) {
  return t.studentsInLevel;
}

function unlocked(t) {
  return t.unlocked;
}

function filterName(t) {
  return t.filterName;
}

function percentageStudents(t, totalStudents) {
  if (totalStudents === 0) {
    return 0.0;
  } else {
    return t.studentsInLevel / totalStudents * 100.0;
  }
}

function make(id, number, studentsInLevel, unlocked, filterName) {
  return {
          id: id,
          number: number,
          filterName: filterName,
          studentsInLevel: studentsInLevel,
          unlocked: unlocked
        };
}

function sort(levels) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.number - y.number | 0;
              }), levels);
}

function levelsCompletedByAllStudents(levels) {
  var aux = function (_completedLevels, _levels) {
    while(true) {
      var levels = _levels;
      var completedLevels = _completedLevels;
      if (!levels) {
        return completedLevels;
      }
      var head = levels.hd;
      if (head.studentsInLevel !== 0) {
        return completedLevels;
      }
      _levels = levels.tl;
      _completedLevels = $$Array.append(completedLevels, [head]);
      continue ;
    };
  };
  var ls = aux([], $$Array.to_list(sort(levels)));
  if (ls.length === levels.length) {
    return [];
  } else {
    return ls;
  }
}

function shortName(t) {
  return LevelLabel.format(true, undefined, String(t.number));
}

function decode(json) {
  return make(Json_decode.field("id", Json_decode.string, json), Json_decode.field("number", Json_decode.$$int, json), Json_decode.field("studentsInLevel", Json_decode.$$int, json), Json_decode.field("unlocked", Json_decode.bool, json), Json_decode.field("filterName", Json_decode.string, json));
}

export {
  id ,
  number ,
  studentsInLevel ,
  unlocked ,
  filterName ,
  percentageStudents ,
  make ,
  sort ,
  levelsCompletedByAllStudents ,
  shortName ,
  decode ,
  
}
/* ArrayUtils Not a pure module */
