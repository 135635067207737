// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DOMPurify from "./DOMPurify.bs.js";
import MarkdownIt from "./markdownIt";

function parse(prim) {
  return MarkdownIt(prim);
}

function sanitize(html, profile) {
  if (profile) {
    return DOMPurify.sanitizedHTMLOpt(html, DOMPurify.makeOptions(undefined, [
                    "p",
                    "em",
                    "strong",
                    "del",
                    "s",
                    "a",
                    "sup",
                    "sub"
                  ], undefined));
  } else {
    return DOMPurify.sanitizedHTMLOpt(html, DOMPurify.makeOptions(["iframe"], undefined, undefined));
  }
}

function toSafeHTML(markdown, profile) {
  return sanitize(MarkdownIt(markdown), profile);
}

export {
  parse ,
  sanitize ,
  toSafeHTML ,
  
}
/* DOMPurify Not a pure module */
