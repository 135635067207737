import { I18n } from "i18n-js";
import translations from "../locales.json";

const translationsArray = Object.entries(translations)
const selectedTranslation = translationsArray.filter(([key, value]) => key == window.pupilfirst.locale)
const translationHash = Object.fromEntries(selectedTranslation)

const i18n = new I18n(translationHash);

i18n.enableFallback = true;
i18n.missingTranslationPrefix = "Missing translation: ";
i18n.defaultLocale = window.pupilfirst.locale;
i18n.locale = window.pupilfirst.locale;
i18n.locales.register(window.pupilfirst.defaultLocale, window.pupilfirst.availableLocales)

export default i18n;
