// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../Api.bs.js";
import * as I18n from "../utils/I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "./FaIcon.bs.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as DateTime from "../utils/DateTime.bs.js";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as ReactUtils from "../utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as EmojiPicker from "./EmojiPicker.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as MarkdownBlock from "./MarkdownBlock.bs.js";
import * as DisablingCover from "./DisablingCover.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as TextareaAutosize from "../utils/TextareaAutosize.bs.js";
import * as AuthenticityToken from "../utils/AuthenticityToken.bs.js";
import * as Webapi__Dom__Element from "../../../../node_modules/bs-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as Webapi__Dom__HtmlElement from "../../../../node_modules/bs-webapi/src/Webapi/Dom/Webapi__Dom__HtmlElement.bs.js";

var InvalidModeForPreview = /* @__PURE__ */Caml_exceptions.create("MarkdownEditor.InvalidModeForPreview");

import "./MarkdownEditor.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.MarkdownEditor";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ClickPreview */0 :
          var match = state.mode;
          var mode;
          mode = match.TAG === /* Fullscreen */0 ? (
              match._0 === "Preview" ? ({
                    TAG: /* Fullscreen */0,
                    _0: "Editor"
                  }) : ({
                    TAG: /* Fullscreen */0,
                    _0: "Preview"
                  })
            ) : (
              match._0 === "Preview" ? ({
                    TAG: /* Windowed */1,
                    _0: "Editor"
                  }) : ({
                    TAG: /* Windowed */1,
                    _0: "Preview"
                  })
            );
          return {
                  id: state.id,
                  mode: mode,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* ClickSplit */1 :
          var match$1 = state.mode;
          var mode$1;
          mode$1 = match$1.TAG === /* Fullscreen */0 && match$1._0 === "Split" ? ({
                TAG: /* Fullscreen */0,
                _0: "Editor"
              }) : ({
                TAG: /* Fullscreen */0,
                _0: "Split"
              });
          return {
                  id: state.id,
                  mode: mode$1,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* ClickFullscreen */2 :
          var match$2 = state.mode;
          var mode$2;
          mode$2 = match$2.TAG === /* Fullscreen */0 ? (
              match$2._0 === "Preview" ? ({
                    TAG: /* Windowed */1,
                    _0: "Preview"
                  }) : ({
                    TAG: /* Windowed */1,
                    _0: "Editor"
                  })
            ) : (
              match$2._0 === "Preview" ? ({
                    TAG: /* Fullscreen */0,
                    _0: "Preview"
                  }) : ({
                    TAG: /* Fullscreen */0,
                    _0: "Editor"
                  })
            );
          return {
                  id: state.id,
                  mode: mode$2,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* PressEscapeKey */3 :
          var match$3 = state.mode;
          var mode$3;
          if (match$3.TAG === /* Fullscreen */0) {
            var match$4 = match$3._0;
            mode$3 = match$4 === "Preview" ? ({
                  TAG: /* Windowed */1,
                  _0: "Preview"
                }) : ({
                  TAG: /* Windowed */1,
                  _0: "Editor"
                });
          } else {
            mode$3 = match$3._0 === "Preview" ? ({
                  TAG: /* Windowed */1,
                  _0: "Preview"
                }) : ({
                  TAG: /* Windowed */1,
                  _0: "Editor"
                });
          }
          return {
                  id: state.id,
                  mode: mode$3,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* SetUploading */4 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: state.selection,
                  uploadState: /* Uploading */0,
                  currentFileName: state.currentFileName
                };
      case /* FinishUploading */5 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: state.selection,
                  uploadState: /* ReadyToUpload */{
                    _0: undefined
                  },
                  currentFileName: state.currentFileName
                };
      case /* ClearFile */6 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: undefined
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* SetSelection */0 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: action._0,
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* BumpSelection */1 :
          var offset = action._0;
          var match$5 = state.selection;
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: [
                    match$5[0] + offset | 0,
                    match$5[1] + offset | 0
                  ],
                  uploadState: state.uploadState,
                  currentFileName: state.currentFileName
                };
      case /* SetUploadError */2 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: state.selection,
                  uploadState: /* ReadyToUpload */{
                    _0: action._0
                  },
                  currentFileName: state.currentFileName
                };
      case /* SelectFile */3 :
          return {
                  id: state.id,
                  mode: state.mode,
                  selection: state.selection,
                  uploadState: state.uploadState,
                  currentFileName: action._0
                };
      
    }
  }
}

function computeInitialState(param) {
  var textareaId = param[1];
  var id = textareaId !== undefined ? textareaId : DateTime.randomId(undefined);
  var length = param[0].length;
  return {
          id: id,
          mode: param[2],
          selection: [
            length,
            length
          ],
          uploadState: /* ReadyToUpload */{
            _0: undefined
          },
          currentFileName: undefined
        };
}

function containerClasses(mode) {
  if (mode.TAG === /* Fullscreen */0) {
    return "bg-black-75 fixed z-50 top-0 left-0 h-screen w-screen flex flex-col overscroll-contain";
  } else {
    return "relative bg-black-75 overscroll-contain";
  }
}

function modeIcon(desiredMode, currentMode) {
  var icon = desiredMode === "Preview" ? (
      currentMode._0 === "Preview" ? "fas fa-pen-nib" : "fas fa-eye"
    ) : (
      desiredMode === "Split" ? (
          currentMode.TAG === /* Fullscreen */0 && currentMode._0 === "Split" ? "far fa-window-maximize" : "fas fa-columns"
        ) : (
          currentMode.TAG === /* Fullscreen */0 ? "fas fa-compress" : "fas fa-expand"
        )
    );
  return React.createElement(FaIcon.make, {
              classes: "fa-fw " + icon
            });
}

function modeLabel(desiredMode, currentMode) {
  if (desiredMode === "Preview") {
    if (currentMode._0 === "Preview") {
      return t(undefined, undefined, "mode_label_preview_exit");
    } else {
      return t(undefined, undefined, "mode_label_preview");
    }
  } else if (desiredMode === "Split") {
    if (currentMode.TAG === /* Fullscreen */0 && currentMode._0 === "Split") {
      return t(undefined, undefined, "mode_label_split_exit");
    } else {
      return t(undefined, undefined, "mode_label_split");
    }
  } else if (currentMode.TAG === /* Fullscreen */0) {
    return t(undefined, undefined, "mode_label_fullscreen_exit");
  } else {
    return t(undefined, undefined, "mode_label_fullscreen");
  }
}

function onClickFullscreen(state, send, _event) {
  var match = state.mode;
  if (match.TAG !== /* Fullscreen */0) {
    TextareaAutosize.destroy(state.id);
  }
  return Curry._1(send, /* ClickFullscreen */2);
}

function onClickPreview(state, send, _event) {
  var match = state.mode;
  if (match.TAG !== /* Fullscreen */0) {
    if (match._0 === "Preview") {
      
    } else {
      TextareaAutosize.destroy(state.id);
    }
  }
  return Curry._1(send, /* ClickPreview */0);
}

function onClickSplit(state, send, _event) {
  var match = state.mode;
  if (match.TAG !== /* Fullscreen */0) {
    TextareaAutosize.destroy(state.id);
  }
  return Curry._1(send, /* ClickSplit */1);
}

function insertAt(textToInsert, position, sourceText) {
  var head = $$String.sub(sourceText, 0, position);
  var tail = $$String.sub(sourceText, position, sourceText.length - position | 0);
  return head + (textToInsert + tail);
}

function wrapWith(wrapper, selectionStart, selectionEnd, sourceText) {
  var head = $$String.sub(sourceText, 0, selectionStart);
  var selection = $$String.sub(sourceText, selectionStart, selectionEnd - selectionStart | 0);
  var tail = $$String.sub(sourceText, selectionEnd, sourceText.length - selectionEnd | 0);
  return head + (wrapper + (selection + (wrapper + tail)));
}

function updateTextareaAfterDelay(state, param) {
  var endPosition = param[1];
  var startPosition = param[0];
  var match = state.mode;
  if (match.TAG !== /* Fullscreen */0) {
    setTimeout((function (param) {
            return TextareaAutosize.update(state.id);
          }), 25);
  }
  var element = document.getElementById(state.id);
  if (!(element == null)) {
    setTimeout((function (param) {
            element.setSelectionRange(startPosition, endPosition);
            return Belt_Option.mapWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(document.getElementById(state.id)), Webapi__Dom__Element.asHtmlElement), undefined, (function (prim) {
                          prim.focus();
                          
                        }));
          }), 25);
    return ;
  }
  
}

function finalizeChange(newValue, state, send, onChange, offsetChange) {
  var match = state.selection;
  Curry._1(send, offsetChange.NAME === "BumpSelection" ? ({
            TAG: /* BumpSelection */1,
            _0: offsetChange.VAL
          }) : ({
            TAG: /* SetSelection */0,
            _0: offsetChange.VAL
          }));
  var match$1;
  if (offsetChange.NAME === "BumpSelection") {
    var offset = offsetChange.VAL;
    match$1 = [
      match[0] + offset | 0,
      match[1] + offset | 0
    ];
  } else {
    var match$2 = offsetChange.VAL;
    match$1 = [
      match$2[0],
      match$2[1]
    ];
  }
  Curry._1(onChange, newValue);
  return updateTextareaAfterDelay(state, [
              match$1[0],
              match$1[1]
            ]);
}

function insertAndWrapper(phraseModifer) {
  switch (phraseModifer) {
    case /* Bold */0 :
        return [
                "**" + t(undefined, undefined, "bold_insert") + "**",
                "**"
              ];
    case /* Italic */1 :
        return [
                "*" + t(undefined, undefined, "italic_insert") + "*",
                "*"
              ];
    case /* Strikethrough */2 :
        return [
                "~~" + t(undefined, undefined, "strikethrough_insert") + "~~",
                "~~"
              ];
    
  }
}

function modifyPhrase(oldValue, state, send, onChange, phraseModifer) {
  var match = state.selection;
  var selectionEnd = match[1];
  var selectionStart = match[0];
  var match$1 = insertAndWrapper(phraseModifer);
  var wrapper = match$1[1];
  var insert = match$1[0];
  var newValue = selectionStart === selectionEnd ? insertAt(insert, selectionStart, oldValue) : wrapWith(wrapper, selectionStart, selectionEnd, oldValue);
  return finalizeChange(newValue, state, send, onChange, selectionStart === selectionEnd ? ({
                  NAME: "SetSelection",
                  VAL: [
                    selectionStart + wrapper.length | 0,
                    (selectionStart + insert.length | 0) - wrapper.length | 0
                  ]
                }) : ({
                  NAME: "BumpSelection",
                  VAL: wrapper.length
                }));
}

function controlsContainerClasses(mode) {
  var tmp;
  tmp = mode.TAG === /* Fullscreen */0 ? "border-primary-500 " : "rounded-t border-primary-500";
  return "border text-sm px-2 flex justify-between items-end " + tmp;
}

function controls(disabled, value, state, send, onChange) {
  var buttonClasses = "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 ";
  var mode = state.mode;
  var curriedModifyPhrase = function (param) {
    return modifyPhrase(value, state, send, onChange, param);
  };
  var valueReference = React.useRef(value);
  valueReference.current = value;
  var match = state.selection;
  var currentSelectionStart = match[0];
  var selectionStart = React.useRef(currentSelectionStart);
  selectionStart.current = currentSelectionStart;
  var handleEmojiChange = function (e) {
    return Curry._1(onChange, insertAt(e.native, selectionStart.current, valueReference.current));
  };
  var tmp;
  var exit = 0;
  if (mode.TAG === /* Fullscreen */0) {
    if (mode._0 === "Preview") {
      tmp = React.createElement("div", undefined);
    } else {
      exit = 1;
    }
  } else if (mode._0 === "Preview") {
    tmp = React.createElement("div", undefined);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: "border border-primary-500 rounded-t border-b-0",
          role: "toolbar"
        }, React.createElement("button", {
              "aria-label": t(undefined, undefined, "control_label_bold"),
              className: buttonClasses,
              title: t(undefined, undefined, "control_label_bold"),
              disabled: disabled,
              type: "button",
              onClick: (function (param) {
                  return curriedModifyPhrase(/* Bold */0);
                })
            }, React.createElement("i", {
                  className: "fas fa-bold fa-fw"
                })), React.createElement("button", {
              "aria-label": t(undefined, undefined, "control_label_italic"),
              className: "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 border-l border-primary-500",
              title: t(undefined, undefined, "control_label_italic"),
              disabled: disabled,
              type: "button",
              onClick: (function (param) {
                  return curriedModifyPhrase(/* Italic */1);
                })
            }, React.createElement("i", {
                  className: "fas fa-italic fa-fw"
                })), React.createElement("button", {
              "aria-label": t(undefined, undefined, "control_label_strikethrough"),
              className: "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 border-l border-primary-500",
              title: t(undefined, undefined, "control_label_strikethrough"),
              disabled: disabled,
              type: "button",
              onClick: (function (param) {
                  return curriedModifyPhrase(/* Strikethrough */2);
                })
            }, React.createElement("i", {
                  className: "fas fa-strikethrough fa-fw"
                })), React.createElement(EmojiPicker.make, {
              className: "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 border-l border-gray-400 hidden md:block",
              title: t(undefined, undefined, "emoji_picker"),
              onChange: handleEmojiChange
            }));
  }
  var tmp$1;
  tmp$1 = mode.TAG === /* Fullscreen */0 ? React.createElement("span", {
          "aria-hidden": true,
          className: "ml-2 text-xs font-semibold"
        }, t(undefined, undefined, "exit_full_screen_label")) : null;
  return React.createElement("div", {
              className: controlsContainerClasses(state.mode)
            }, tmp, React.createElement("div", {
                  className: "py-1"
                }, React.createElement("button", {
                      "aria-label": modeLabel("Preview", mode),
                      className: "rounded px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 ",
                      title: modeLabel("Preview", mode),
                      disabled: disabled,
                      type: "button",
                      onClick: (function (param) {
                          return onClickPreview(state, send, param);
                        })
                    }, modeIcon("Preview", mode)), React.createElement("button", {
                      "aria-label": modeLabel("Split", mode),
                      className: "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 rounded ml-1 hidden md:inline",
                      title: modeLabel("Split", mode),
                      disabled: disabled,
                      type: "button",
                      onClick: (function (param) {
                          return onClickSplit(state, send, param);
                        })
                    }, modeIcon("Split", mode)), React.createElement("button", {
                      "aria-label": modeLabel("Fullscreen", mode),
                      className: "px-2 py-1 hover:bg-gray-300 hover:text-primary-500 focus:outline-none focus:bg-white focus:text-primary-500 rounded  ml-1 hidden md:inline",
                      title: modeLabel("Fullscreen", mode),
                      disabled: disabled,
                      type: "button",
                      onClick: (function (param) {
                          return onClickFullscreen(state, send, param);
                        })
                    }, modeIcon("Fullscreen", mode), tmp$1)));
}

function modeClasses(mode) {
  if (mode.TAG === /* Fullscreen */0) {
    return "flex grow";
  } else {
    return "";
  }
}

function editorContainerClasses(mode) {
  var tmp;
  if (mode.TAG === /* Fullscreen */0) {
    var match = mode._0;
    tmp = match === "Preview" ? "hidden" : (
        match === "Split" ? "w-1/2" : "w-full"
      );
  } else {
    tmp = mode._0 === "Preview" ? "hidden" : "border-l";
  }
  return "border-r border-primary-500 " + tmp;
}

function previewType(mode) {
  if (mode.TAG === /* Fullscreen */0) {
    var match = mode._0;
    if (match === "Preview") {
      return "FullscreenPreview";
    }
    if (match === "Split") {
      return "FullscreenSplit";
    }
    throw {
          RE_EXN_ID: InvalidModeForPreview,
          Error: new Error()
        };
  }
  if (mode._0 === "Preview") {
    return "WindowedPreview";
  }
  throw {
        RE_EXN_ID: InvalidModeForPreview,
        Error: new Error()
      };
}

function previewContainerClasses(mode) {
  var match = previewType(mode);
  return "border-primary-500 " + (
          match === "FullscreenSplit" ? "w-1/2 relative" : (
              match === "FullscreenPreview" ? "w-screen mx-auto" : "markdown-editor__windowed-preview-container border-l border-r border-b rounded-b px-2 md:px-3"
            )
        );
}

function previewClasses(mode) {
  if (mode.TAG !== /* Fullscreen */0) {
    return "";
  }
  var match = mode._0;
  if (match === "Split" || match === "Preview") {
    return "markdown-editor__fullscreen-preview-wrapper absolute max-h-full overflow-auto w-full px-4 pb-8";
  } else {
    return "";
  }
}

function focusOnEditor(id) {
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(document.getElementById(id)), Webapi__Dom__HtmlElement.ofElement), undefined, (function (element) {
                element.focus();
                
              }));
}

function handleUploadFileResponse(oldValue, state, send, onChange, json) {
  var errors = Json_decode.field("errors", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), json);
  if (!Caml_obj.caml_equal(errors, [])) {
    return Curry._1(send, {
                TAG: /* SetUploadError */2,
                _0: t(undefined, undefined, "error_prefix") + " " + errors.join(", ")
              });
  }
  var markdownEmbedCode = Json_decode.field("markdownEmbedCode", Json_decode.string, json);
  var insert = "\n" + (markdownEmbedCode + "\n");
  var match = state.selection;
  var newValue = insertAt(insert, match[1], oldValue);
  finalizeChange(newValue, state, send, onChange, {
        NAME: "BumpSelection",
        VAL: newValue.length - oldValue.length | 0
      });
  return Curry._1(send, /* FinishUploading */5);
}

function submitForm(formId, oldValue, state, send, onChange) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(document.getElementById(formId)), undefined, (function (element) {
                var formData = new FormData(element);
                return Api.sendFormData("/markdown_attachments/", formData, (function (param) {
                              return handleUploadFileResponse(oldValue, state, send, onChange, param);
                            }), (function (param) {
                              return Curry._1(send, {
                                          TAG: /* SetUploadError */2,
                                          _0: t(undefined, undefined, "error_unexpected")
                                        });
                            }));
              }));
}

function attachFile(fileFormId, oldValue, state, send, onChange, $$event) {
  var files = $$event.target.files;
  if (files.length === 0) {
    return ;
  }
  var file = Caml_array.get(files, 0);
  var maxFileSize = window.pupilfirst.maxUploadFileSize;
  Curry._1(send, {
        TAG: /* SelectFile */3,
        _0: $$event.target.value
      });
  var error = file.size > maxFileSize ? t(undefined, undefined, "error_maximum_file_size") : undefined;
  if (error !== undefined) {
    return Curry._1(send, {
                TAG: /* SetUploadError */2,
                _0: error
              });
  } else {
    Curry._1(send, /* SetUploading */4);
    submitForm(fileFormId, oldValue, state, send, onChange);
    return Curry._1(send, /* ClearFile */6);
  }
}

function footerContainerClasses(mode) {
  var tmp;
  tmp = mode.TAG === /* Fullscreen */0 ? "" : "rounded-b ";
  return "markdown-editor__footer-container border flex justify-end items-center border-primary-500 hover:border-gray-50 " + tmp;
}

function footer(disabled, fileUpload, oldValue, state, send, onChange) {
  var id = state.id;
  var fileFormId = id + "-file-form";
  var fileInputId = id + "-file-input";
  var match = state.mode;
  if (match.TAG === /* Fullscreen */0) {
    if (match._0 === "Preview") {
      return null;
    }
    
  } else if (match._0 === "Preview") {
    return null;
  }
  var file = state.currentFileName;
  var error = state.uploadState;
  var tmp;
  if (error) {
    var error$1 = error._0;
    tmp = React.createElement("label", {
          className: "text-xs px-3 py-2 flex grow " + (
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          ),
          htmlFor: fileInputId
        }, error$1 !== undefined ? React.createElement("span", {
                className: "text-red-500"
              }, React.createElement("i", {
                    className: "fas fa-exclamation-triangle mr-2"
                  }), error$1) : React.createElement("span", undefined, React.createElement("i", {
                    className: "far fa-file-image mr-2"
                  }), t(undefined, undefined, "attach_file_label")));
  } else {
    tmp = React.createElement("span", {
          className: "text-xs px-3 py-2 grow cursor-wait"
        }, React.createElement("i", {
              className: "fas fa-spinner fa-pulse mr-2"
            }), t(undefined, undefined, "file_upload_wait"));
  }
  return React.createElement("div", {
              className: footerContainerClasses(state.mode)
            }, ReactUtils.nullUnless(React.createElement("form", {
                      className: "relative flex items-center flex-wrap flex-1 text-sm font-semibold " + (
                        disabled ? "" : "hover:bg-primary-500 focus-within:outline-none"
                      ),
                      id: fileFormId
                    }, React.createElement("input", {
                          name: "authenticity_token",
                          type: "hidden",
                          value: AuthenticityToken.fromHead(undefined)
                        }), React.createElement("input", {
                          className: "absolute w-0 h-0 focus:outline-none",
                          id: fileInputId,
                          disabled: disabled,
                          multiple: false,
                          name: "markdown_attachment[file]",
                          type: "file",
                          value: file !== undefined ? file : "",
                          onChange: (function (param) {
                              return attachFile(fileFormId, oldValue, state, send, onChange, param);
                            })
                        }), tmp), fileUpload), React.createElement("a", {
                  "aria-label": t(undefined, undefined, "help_aria_label"),
                  className: "flex items-center px-3 py-2 hover:bg-gray-300 hover:text-red-500 focus:outline-none cursor-pointer",
                  href: "/help/markdown_editor",
                  target: "_blank"
                }, React.createElement("i", {
                      className: "fab fa-markdown text-sm"
                    }), React.createElement("span", {
                      className: "text-xs ml-1 font-semibold hidden sm:inline"
                    }, t(undefined, undefined, "help_label"))));
}

function textareaClasses(mode, dynamicHeight) {
  var editorClasses = dynamicHeight ? "w-full outline-none font-mono " : "markdown-editor__textarea w-full outline-none font-mono ";
  var tmp;
  tmp = mode.TAG === /* Fullscreen */0 ? "markdown-editor__textarea--full-screen px-3 pt-4 pb-8 h-full resize-none" : "p-3";
  return editorClasses + "align-top focus:ring-1 focus:ring-focusColor-500 bg-black-50 text-white " + tmp;
}

function onChangeWrapper(onChange, $$event) {
  return Curry._1(onChange, $$event.target.value);
}

function onSelect(send, $$event) {
  var htmlInputElement = $$event.target;
  var selection_0 = htmlInputElement.selectionStart;
  var selection_1 = htmlInputElement.selectionEnd;
  var selection = [
    selection_0,
    selection_1
  ];
  return Curry._1(send, {
              TAG: /* SetSelection */0,
              _0: selection
            });
}

function handleEscapeKey(send, $$event) {
  var _anyOtherKey = $$event.key;
  if (_anyOtherKey === "Escape") {
    return Curry._1(send, /* PressEscapeKey */3);
  }
  
}

function handleKeyboardControls(value, state, send, onChange, $$event) {
  var curriedModifyPhrase = function (param) {
    return modifyPhrase(value, state, send, onChange, param);
  };
  var _anyOtherKey = $$event.key;
  switch (_anyOtherKey) {
    case "b" :
        if ($$event.ctrlKey || $$event.metaKey) {
          return curriedModifyPhrase(/* Bold */0);
        } else {
          return ;
        }
    case "i" :
        if ($$event.ctrlKey || $$event.metaKey) {
          return curriedModifyPhrase(/* Italic */1);
        } else {
          return ;
        }
    default:
      return ;
  }
}

function scrollTargetToSource(source, target, _event) {
  var sourceScrollTop = source.scrollTop;
  var sourceOffsetHeight = source.offsetHeight;
  var sourceScrollHeight = source.scrollHeight;
  var scrollFraction = sourceScrollTop / (sourceScrollHeight - sourceOffsetHeight | 0);
  var maxTargetScrollTop = target.scrollHeight - target.offsetHeight | 0;
  target.scrollTop = scrollFraction * maxTargetScrollTop;
  
}

var ScrollSync = {
  scrollTargetToSource: scrollTargetToSource
};

function MarkdownEditor(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var profile = Props.profile;
  var textareaId = Props.textareaId;
  var maxLengthOpt = Props.maxLength;
  var defaultModeOpt = Props.defaultMode;
  var placeholder = Props.placeholder;
  var tabIndex = Props.tabIndex;
  var textAreaName = Props.textAreaName;
  var fileUploadOpt = Props.fileUpload;
  var disabledOpt = Props.disabled;
  var dynamicHeightOpt = Props.dynamicHeight;
  var maxLength = maxLengthOpt !== undefined ? maxLengthOpt : 1000;
  var defaultMode = defaultModeOpt !== undefined ? defaultModeOpt : ({
        TAG: /* Windowed */1,
        _0: "Editor"
      });
  var fileUpload = fileUploadOpt !== undefined ? fileUploadOpt : true;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var dynamicHeight = dynamicHeightOpt !== undefined ? dynamicHeightOpt : false;
  var match = React.useReducer(reducer, [
        value,
        textareaId,
        defaultMode
      ], computeInitialState);
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.mode;
          if (match.TAG !== /* Fullscreen */0) {
            if (match._0 === "Preview") {
              
            } else {
              TextareaAutosize.create(state.id);
            }
          }
          return (function (param) {
                    return TextareaAutosize.destroy(state.id);
                  });
        }), [state.mode]);
  React.useEffect((function () {
          var curriedHandler = function (param) {
            return handleEscapeKey(send, param);
          };
          var documentEventTarget = document;
          documentEventTarget.addEventListener("keydown", curriedHandler);
          return (function (param) {
                    documentEventTarget.removeEventListener("keydown", curriedHandler);
                    
                  });
        }), []);
  React.useEffect(function () {
        var curriedHandler = function (param) {
          return handleKeyboardControls(value, state, send, onChange, param);
        };
        var textareaEventTarget = Belt_Option.map(Caml_option.nullable_to_opt(document.getElementById(state.id)), (function (prim) {
                return prim;
              }));
        Belt_Option.mapWithDefault(textareaEventTarget, undefined, (function (param) {
                param.addEventListener("keydown", curriedHandler);
                
              }));
        return (function (param) {
                  return Belt_Option.mapWithDefault(textareaEventTarget, undefined, (function (param) {
                                param.removeEventListener("keydown", curriedHandler);
                                
                              }));
                });
      });
  React.useEffect((function () {
          var textarea = document.getElementById(state.id);
          var preview = document.getElementById(state.id + "-preview");
          if (textarea == null) {
            return ;
          }
          if (preview == null) {
            return ;
          }
          var scrollCallback = function (param) {
            return scrollTargetToSource(textarea, preview, param);
          };
          var _anyOtherMode = state.mode;
          if (_anyOtherMode.TAG === /* Fullscreen */0) {
            if (_anyOtherMode._0 === "Split") {
              textarea.addEventListener("scroll", scrollCallback);
              return (function (param) {
                        textarea.removeEventListener("scroll", scrollCallback);
                        
                      });
            } else {
              textarea.removeEventListener("scroll", scrollCallback);
              return ;
            }
          }
          textarea.removeEventListener("scroll", scrollCallback);
          
        }), [state.mode]);
  var tmp = {
    "aria-label": "Markdown editor",
    className: textareaClasses(state.mode, dynamicHeight),
    id: state.id,
    disabled: disabled,
    maxLength: maxLength,
    rows: 4,
    value: value,
    onChange: (function (param) {
        return Curry._1(onChange, param.target.value);
      }),
    onSelect: (function (param) {
        return onSelect(send, param);
      })
  };
  if (tabIndex !== undefined) {
    tmp.tabIndex = Caml_option.valFromOption(tabIndex);
  }
  if (textAreaName !== undefined) {
    tmp.name = Caml_option.valFromOption(textAreaName);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  var match$1 = state.mode;
  var tmp$1;
  var exit = 0;
  if (match$1.TAG === /* Fullscreen */0) {
    var match$2 = match$1._0;
    if (match$2 === "Split" || match$2 === "Preview") {
      exit = 1;
    } else {
      tmp$1 = null;
    }
  } else if (match$1._0 === "Preview") {
    exit = 1;
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    tmp$1 = React.createElement("div", {
          className: previewContainerClasses(state.mode)
        }, React.createElement("div", {
              className: previewClasses(state.mode),
              id: state.id + "-preview"
            }, React.createElement(MarkdownBlock.make, {
                  markdown: value,
                  className: "markdown-editor__fullscreen-preview-editor",
                  profile: profile
                })));
  }
  return React.createElement("div", {
              className: containerClasses(state.mode)
            }, controls(disabled, value, state, send, onChange), React.createElement("div", {
                  className: modeClasses(state.mode)
                }, React.createElement("div", {
                      className: editorContainerClasses(state.mode)
                    }, React.createElement(DisablingCover.make, {
                          disabled: state.uploadState === /* Uploading */0,
                          message: "Uploading...",
                          containerClasses: "h-full",
                          children: React.createElement("textarea", tmp)
                        })), tmp$1), footer(disabled, fileUpload, value, state, send, onChange));
}

var make = MarkdownEditor;

export {
  InvalidModeForPreview ,
  str ,
  t ,
  reducer ,
  computeInitialState ,
  containerClasses ,
  modeIcon ,
  modeLabel ,
  onClickFullscreen ,
  onClickPreview ,
  onClickSplit ,
  insertAt ,
  wrapWith ,
  updateTextareaAfterDelay ,
  finalizeChange ,
  insertAndWrapper ,
  modifyPhrase ,
  controlsContainerClasses ,
  controls ,
  modeClasses ,
  editorContainerClasses ,
  previewType ,
  previewContainerClasses ,
  previewClasses ,
  focusOnEditor ,
  handleUploadFileResponse ,
  submitForm ,
  attachFile ,
  footerContainerClasses ,
  footer ,
  textareaClasses ,
  onChangeWrapper ,
  onSelect ,
  handleEscapeKey ,
  handleKeyboardControls ,
  ScrollSync ,
  make ,
  
}
/*  Not a pure module */
